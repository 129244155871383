html,body{
  overflow-x: hidden;
}
.content{
  max-width: $content-width;
  margin: auto;
  padding-left: 30px;
  padding-right: 30px;
  &.content-fluid{
    max-width: none;
  }
  &.content-small{
    max-width: 820px;
  }
  &.content-medium{
    max-width: 1080px;
  }
  &.content-nopadding{
    padding: 0;
  }
  &.content-nopadding-top{
    padding-top: 0;
  }
  &.content-nopadding-bottom{
    padding-bottom: 0;
  }
  .border{
    border-top: 2px solid $brand-primary;
  }
}
.background-gradient,
.module.background-gradient{
  background: linear-gradient(to right, $brand-primary-gradient, $brand-primary);
}
.background-gradient-diagonally,
.module.background-gradient-diagonally{
  background: linear-gradient(to bottom right, $brand-primary-gradient, $brand-primary);
}
.background-gray,
.module.background-gray{
  background-color: $brand-gray;
}
.btn{
  display: inline-block;
  font-weight: 600;
  background: $brand-primary;
  border: 1px solid $brand-primary;
  color: #fff;
  transition: background-color .3s ease-in, color .3s ease-in;
  padding: 15px 70px;
  text-transform: uppercase;
  &:hover{
    background: #fff;
    color: $brand-primary;
  }
  &.btn-inverse{
    background: transparent;
    border: 1px solid #fff;
    &:hover{
      background: #fff;
      color: $brand-primary;
    }
  }
  &.btn-small{
    padding: 5px 20px;
  }
}
.cta-row{
  margin-top: 40px;
}
hr{
  max-width: $content-width;
}
@media(min-width: $breakpoint-mobile){
  .viewport-height{
    position: relative;
    .viewport-height-center{
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      object-fit: contain;
      transform: translate(-50%,-50%);
    }
  }
}
.row{
  margin-left: -30px;
  margin-right: -30px;
  &:after{
    display: block;
    content: ' ';
    clear: both;
  }
  .col,
  .col-60,
  .col-50,
  .col-40,
  .col-14{
    position: relative;
    margin-bottom: 40px;
    padding: 0 30px;
    @media(min-width: $breakpoint-mobile){
      float: left;
      margin-bottom: 0;
    }
  }
  .col-14{
    float: left;
  }
  .col-mobile{
    float: left;
  }
  .col-60{
    @media(min-width: $breakpoint-mobile){
      width: 60%;
    }
  }
  .col-50{
    @media(min-width: $breakpoint-mobile){
      width: 50%;
    }
  }
  .col-40{
    @media(min-width: $breakpoint-mobile){
      width: 40%;
    }
  }
  .col-14{
    width: 33.33333%;
    @media(min-width: $breakpoint-mobile){
      width: 14.285%;
    }
  }
  .col-centered{
    float: none;
    margin: auto;
  }
}
.vertical-align{
  display: table;
  .vertical-align--element{
    display: table-cell;
    vertical-align: middle;
  }
}
.text-center{
  text-align: center;
}
.ordered-list{
  text-align: left;
  .ordered-list--item{
    width: 100%;
    margin-bottom: 20px;
    &:after{
      display: block;
      content: ' ';
      clear: both;
    }
    .ordered-list--number,
    .ordered-list--text{
      float: left;
    }
    .ordered-list--number{
      color: $brand-primary;
      font-weight: 700;
      font-size: 40px;
      width: 15%;
      height: 100%;
      margin-bottom: 0;
    }
    .ordered-list--text{
      width: 85%;
    }
  }
}
.img-desktop{
  display: none;
}
@media(min-width: $breakpoint-mobile){
  .img-mobile-only{
    display: none !important;
  }
  .img-tablet{
    display: block;
  }
}
@media(min-width: $breakpoint-tablet){
  .img-mobile{
    display: none;
  }
  .img-tablet{
    display: none;
  }
  .img-desktop{
    display: block;
  }
  .hide-desktop{
    display: none !important;
  }
}