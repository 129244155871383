.module{
  padding: 55px 0;
  background-color: #fff;
  position: relative;
  z-index: 10;
  &.module-no-padding{
    padding-top: 0;
    padding-bottom: 0;
  }
  &.padding-content{
    padding: 0;
    .content{
      padding-top: 55px;
      padding-bottom: 55px;
      &.content-nopadding-top{
        padding-top: 0;
      }
      &.content-nopadding-bottom{
        padding-bottom: 0;
      }
    }
  }
  &.text:first-of-type{
    padding-top: 100px;
  }
}
.module.header{
  position: relative;
  .header--wrapper{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    > .content{
      position: relative;
      height: 100%;
      .header--inner{
        position: absolute;
        bottom: 20px;
        color: #fff;
        @media(min-width: $breakpoint-mobile){
          bottom: 120px;
          max-width: 50%;
        }
      }
    }
  }
  &.viewport-height{
    overflow: hidden;
  }
  &.viewport-height{
    img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }
  &.header-video{
    .video--controls{
      position: absolute;
      bottom: 15px;
      right: 15px;
      @media (min-width: $breakpoint-mobile){
        bottom: 30px;
        right: 30px;
      }
      z-index: 6;
      opacity: 0;
      transition: opacity .3s;
      .mute-button {
        height: 30px;
        width: 30px;
        position: relative;
        overflow: hidden;
        transform: scale(2);
        display: none;
        span {
          display: block;
          width: 8px;
          height: 8px;
          background: #fff;
          margin: 11px 0 0 2px;
          &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent #fff transparent transparent;
            border-width: 10px 14px 10px 15px;
            left: -13px;
            top: 5px;
          }
          &:before {
            transform: rotate(45deg);
            border-radius: 0 50px 0 0;
            content: '';
            position: absolute;
            width: 5px;
            height: 5px;
            border-style: double;
            border-color: #fff;
            border-width: 7px 7px 0 0;
            left: 18px;
            top: 9px;
            transition: all 0.2s ease-out;
          }
        }
        &:hover {
          span:before {
            transform: scale(.8) translate(-3px, 0) rotate(42deg);
          }
        }
        &.mute {
          span:before {
            transform: scale(.5) translate(-15px, 0) rotate(36deg);
            opacity: 0;
          }
        }
      }
    }
    .content{
      position: relative;
      width: 100%;
      height: 100%;
    }
    video{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      min-width: 100%;
      min-height: 100%;
      z-index: 2;
      object-fit: cover;
      &#video-desktop{
        display: none;
      }
      @media (min-width: $breakpoint-menu ) and (orientation: landscape){
        &#video-mobile{
          display: none;
        }
        &#video-desktop{
          display: block;
        }
      }
    }
    &.playing{
      .video--poster{
        opacity: 0;
      }
      .video--controls{
        opacity: 1;
        .mute-button{
          display: inline-block;
        }
      }
    }
  }
}
.module.text{
  p{
    margin-bottom: 10px;
  }
  h3{
    margin-top: 40px;
  }
  table{
    display: none;
    margin-top: 20px;
    height: 120px;
    width: 100%;
    tr{
      td{
        padding: 10px;
      }
    }
  }
  .icon-row{
    margin-top: 25px;
    .icon--wrap{

    }
    img{
      width: 33.33333%;
      float: left;
      padding: 5px;
      max-height: 100px;
      margin-bottom: 20px;
      &:nth-child(odd){
        margin-left: 0;
      }
    }
    &:after{
      display: block;
      content: '';
      clear: both;
    }
  }
  @media (min-width: $breakpoint-tablet){
    table{
      display: table;
    }
    .icon-row{
      display: none;
    }
  }
  &.small-font{
    font-size: 20px;
    line-height: 28px;
  }
}
.module.icons{
  padding-bottom: 0;
  z-index: 1050;
  .icons--container{
    .icons--element{
      float: left;
      width: 50%;
      margin-bottom: 50px;
      @media(min-width: $breakpoint-mobile){
        width: 25%;
        margin-bottom: 100px;
      }
      img{
        margin: auto;
        width: 50%;
        @media(min-width: $breakpoint-mobile){
          width: 40%;
        }
      }
      .icons--element--number{
        @extend h3;
        color: #fff;
        margin-bottom: 10px;
        margin-top: 15px;
        @media(min-width: $breakpoint-mobile){
          margin-top: 30px;
        }
      }
    }
    &:after{
      display: block;
      content: '';
      clear: both;
    }
  }
}
.module.slider{
  padding-top: $navigation-height-mobile;
  @media(min-width: $breakpoint-mobile){
    padding-top: $navigation-height;
  }
  .tns-outer{
    position: relative;
    .tns-nav{
      position: absolute;
      text-align: center;
      z-index: 1;
      width: 100%;
      bottom: 0;
      padding-bottom: 20px;
      button{
        display: inline-block;
        width: 30px;
        height: 30px;
        background: transparent;
        border-radius: 50%;
        border: 2px solid #fff;
        transition: background-color .3s ease-in;
        margin-right: 5px;
        &:hover,
        &.tns-nav-active{
          background: $brand-primary;
        }
      }
    }
  }
}
.module.circle-ksps,
.module.circle-arrows{
  .circle{
    position: relative;
    .circle-center {
      img {
        display: none;
      }
      .circle-center--text{
        text-align: center;
      }
    }
    .circle-box{
      padding: 10px 20px 10px 40px;
      border-radius: 80px;
      margin-top: 20px;
      &:after{
        display: block;
        content: '';
        clear: both;
      }
      .circle-box--text,
      .circle-box--right{
        height: 75px;
      }
      .circle-box--text{
        width: 70%;
        float: left;
        padding-right: 5px;
        font-size: 19px;
        line-height: 24px;
        font-weight: 600;
      }
      .circle-box--right{
        float: left;
        width: 30%;
        .circle-box--icon{
          display: inline-block;
          border-radius: 50%;
          padding: 20px;
        }
      }
      &.left.top{
        background: $accent-color-1;
        .line{
          background: $accent-color-1;
        }
      }
      &.right.top{
        background: $accent-color-2;
        .line{
          background: $accent-color-2;
        }
      }
      &.right.bottom{
        background: $accent-color-3;
        .line{
          background: $accent-color-3;
        }
      }
      &.left.bottom{
        background: $accent-color-4;
        .line{
          background: $accent-color-4;
        }
      }
    }
    @media(min-width: $breakpoint-mobile){
      height: 600px;
      .circle-center{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 5;
        img{
          display: block;
        }
        .circle-center--text{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          z-index: 6;
          width: 50%;
          h2{
            font-size: 27px;
            line-height: 29px;
            font-weight: normal;
          }
        }
      }
      .circle-box{
        position: absolute;
        width: 310px;
        height: 150px;
        display: flex;
        z-index: 3;
        margin-top: 0px;
        .circle-box--text,
        .circle-box--right{
          height: 130px;
        }
        .circle-box--text{
          width: 70%;
          float: left;
          padding-right: 5px;
          font-size: 21px;
          line-height: 25px;
          font-weight: 600;
        }
        .circle-box--right{
          float: left;
          width: 30%;
          .circle-box--icon{
            display: block;
            border-radius: 50%;
            padding: 20px;
          }
        }
        &.left{
          left: 0;
        }
        &.right{
          right: 0;
        }
        &.top{
          top: 0;
        }
        &.bottom{
          bottom: 0;
        }
      }
      .line{
        position: absolute;
        height: 5px;
        width: 100px;
        z-index: 2;
        &.left{
          left: 90%;
        }
        &.right{
          right: 90%;
        }
        &.top{
          top: 90%;
        }
        &.bottom{
          bottom: 90%;
        }
        &.left.top{
          transform: rotate(220deg);
        }
        &.right.top{
          transform: rotate(140deg);
        }
        &.right.bottom{
          transform: rotate(220deg);
        }
        &.left.bottom{
          transform: rotate(140deg);
        }
      }
    }
  }
  .cta-row{
    margin-top: 60px;
  }
}
.module.circle-arrows{
  .circle{
    height: auto;
    @media(min-width: $breakpoint-menu){
      padding: 50px 0;
    }
    img{
      width: 100%;
      height: 100%;
    }
    .circle-center{
      position: static;
      display: block;
      top: auto;
      left: auto;
      transform: translate(0,0);
      width: 60%;
      margin: auto;
    }
    .circle-box{
      border-radius: 0px;
      box-shadow: $box-shadow;
      z-index: 5;
      width: 100%;
      @media(min-width: $breakpoint-menu){
        width: 250px;
        height: 125px;
      }
      align-items: center;
      justify-content: center;
      padding: 20px;
      &.left.top{
        left: 24%;
        top: 2%;
      }
      &.right.top{
        right: 14%;
        top: 30%;
        color: #000;
      }
      &.right.bottom{
        right: 24%;
        bottom: 3%;
        color: #000;
      }
      &.left.bottom{
        left: 16%;
        bottom: 30%;
        color: #000;
      }
    }
  }
}
.module.half-circle-teasers{
  .teasers{
    .teaser{
      float: left;
      width: 50%;
      margin-bottom: 20px;
      .teaser--img-wrapper{
        position: relative;
        padding: 15px;
        img{
          position: relative;
          z-index: 3;
          width: 100%;
        }
        .half-circle--wrapper{
          position: absolute;
          width: 100%;
          height: 50%;
          overflow: hidden;
          top: 0;
          left: 0;
          display: none;
        }
        .half-circle{
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 200%;
          border-radius: 50%;
        }
      }
      &.teaser-1{
        .teaser--img-wrapper{
          .half-circle--wrapper{
            .half-circle{
              background: $accent-color-1;
            }
          }
        }
      }
      &.teaser-2{
        .teaser--img-wrapper{
          .half-circle--wrapper{
            top: auto;
            bottom: 0;
            .half-circle{
              top: auto;
              bottom: 0;
              background: $accent-color-4;
            }
          }
        }
      }
      &.teaser-3{
        .teaser--img-wrapper{
          .half-circle--wrapper {
            .half-circle {
              background: $accent-color-3;
            }
          }
        }
      }
      &.teaser-4{
        .teaser--img-wrapper{
          .half-circle--wrapper {
            top: auto;
            bottom: 0;
            .half-circle {
              top: auto;
              bottom: 0;
              background: $accent-color-2;
            }
          }
        }
      }
    }
    @media(min-width: $breakpoint-menu) {
      padding: 0 40px;
      .teaser {
        margin-left: -40px;
        width: calc(25% + 40px);
        margin-bottom: 0;
        .teaser--img-wrapper {
          padding: 40px;
          img {
            border-radius: 50%;
          }

          .half-circle--wrapper {
            display: block;
          }
        }
        .teaser--text{
          margin-top: 20px;
        }
      }
    }
    &:after{
      display: block;
      content: ' ';
      clear: both;
    }
  }
}
.module.icon-teasers{
  .teasers{
    .teaser{
      position: relative;
      padding: 30px;
      box-shadow: $box-shadow;
      .teaser--icon{
        img{
          width: 30%;
          margin: auto;
        }
      }
      .teaser--text{
        margin-top: 30px;
      }
      .arrow{
        position: absolute;
        width: 75px;
        height: 75px;
        line-height: 75px;
        top: 50%;
        right: -25px;
        transform: translateY(-50%);
        z-index: 4;
        box-shadow: $box-shadow;
        border-radius: 50%;
        text-align: center;
        vertical-align: middle;
        display: none;
        svg{
          display: inline-block;
          vertical-align: middle;
        }
      }
      &.teaser-1{
        z-index: 1;
        background: $accent-color-1;
        .arrow{
          background: $accent-color-1;
        }
      }
      &.teaser-2{
        z-index: 2;
        background: $accent-color-2;
        .arrow{
          background: $accent-color-2;
        }
      }
      &.teaser-3{
        z-index: 3;
        background: $accent-color-3;
        .arrow{
          background: $accent-color-3;
        }
      }
    }
    &:after{
      display: block;
      content: ' ';
      clear: both;
    }
    @media(min-width: $breakpoint-mobile) {
      .teaser{
        float: left;
        width: 33.33333333%;
        box-shadow: $box-shadow-right;
        .teaser--icon {
          margin-top: -55px;
        }
        .arrow{
          display: block;
        }
        &.teaser-1{
          z-index: 3;
        }
        &.teaser-2{
          z-index: 2;
        }
        &.teaser-3{
          z-index: 1;
        }
      }
    }
  }
}
.module.text-image{
  text-align: center;
  h2{
    margin-bottom: 40px;
  }
  ul{
    li{
      &:before{
        content: ' ';
        background-image: url(../assets/img/layout/list-bullet.svg);
        width: 15px;
        height: 15px;
        margin-right: 5px;
        display: inline-block;
      }
    }
  }
  @media(min-width: $breakpoint-mobile) {
    text-align: left;
  }
}
.module.text-image-fullwidth{
  position: relative;
  text-align: center;
  padding-bottom: 0;
  .content{
    padding-bottom: 0;
  }
  h2{
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
  }
  .text-image--image {
    padding: 0;
    margin-bottom: 0;
  }
  ul{
    li{
      position: relative;
      padding-left: 25px;
      &:before{
        position: absolute;
        left: 0;
        margin-top: 10px;
        content: ' ';
        background-image: url(../img/layout/list-bullet.svg);
        width: 15px;
        height: 15px;
        display: inline-block;
      }
    }
  }
  @media(min-width: $breakpoint-mobile) {
    text-align: left;
    .text-image--image {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
    }
  }
}
.sertec-news{
  .module.text-image{
    @media(min-width: $breakpoint-mobile) {
      &:nth-child(2n) {
        .text-image--text {
          left: 50%;
        }

        .text-image--image {
          left: -50%;
        }
      }
    }
  }
}
.module.flags{
  h2{
    margin-bottom: 40px;
  }
  .flag{
    margin-bottom: 40px;
    .flag-wrapper{
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      margin-bottom: 10px;
    }
  }
}
.module.contact{
  h2{
    color: #000;
  }
  .contact--image{
    img{
      display: block;
      max-width: 80%;
      margin: auto;
      border-radius: 50%;
    }
  }
  .contact--text{
    .contact--rows{
      display: block;
      margin: auto;
    }

    .row {
      margin-left: -10px;
      margin-right: -10px;
      margin-bottom: 20px;
      .col{
        padding: 0 10px;
        margin-bottom: 0;
      }
      .col-icon{
        width: 15%;
        line-height: 22px;
        @media (min-width: $breakpoint-mobile){
          line-height: 60px;
          width: 13%;
        }
        img{
          display: inline-block;
        }
      }
      .col-text{
        padding-left: 10px;
        font-weight: 700;
        font-size: 22px;
        line-height: 22px;
        @media (min-width: $breakpoint-mobile){
          line-height: 60px;
          font-size: 40px;
          padding-left: 40px;
        }
      }
    }
  }
}
