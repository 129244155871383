footer{
  padding: 55px 0;
  position: relative;
  text-align: center;
  @media(min-width: $breakpoint-mobile){
    text-align: left;
  }
  p:first-child{
    margin-bottom: 20px;
  }
  .footer-element{
    width: 100%;
    &.footer-right{
      margin-top: 20px;
      ul{
        margin-bottom: 20px;
        li{
          display: inline-block;
        }
      }
    }
    @media(min-width: $breakpoint-mobile){
      float: left;
      width: 50%;
      &.footer-right{
        margin-top: 0;
        position: absolute;
        right: 0;
        bottom: 55px;
      }
    }
  }
  &:after{
    content: '';
    clear: both;
    display: block;
  }
  .footer-icon{
    border-radius: 50%;
    display: block;
  }
}
.footnote{
  margin-top: 10px;
  font-size: 16px;
  line-height: 20px;
}
.cookie-notice{
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
  z-index: 1300;
  padding: 30px 0;
  font-size: 18px;
  line-height: 22px;
  border-top: 1px solid $brand-primary-gradient;
  transition: opacity .3s ease-out;
  opacity: 1;
  &.fade-out{
    opacity: 0;
  }
  &.no-script{
    display: block;
  }
}
