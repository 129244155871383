html,body{
  font-family: 'Titillium Web', sans-serif;
  font-size: 18px;
  line-height: 26px;
  color: $text-color;
}
h1,h2,h3,h4,h5,
.h1,.h2,.h3,.h4,.h5{
  color: $brand-primary;
  margin-bottom: 20px;
  font-weight: 700;
}
.color-inverse{
  color: #fff;
  h1,h2,h3,h4,h5,
  .h1,.h2,.h3,.h4,.h5{
    color: #fff;
  }
}
h1,
.h1{
  font-size: 40px;
  line-height: 44px;
}
h2,
.h2{
  font-size: 32px;
  line-height: 34px;
}
h3,
.h3{
  font-size: 28px;
  line-height: 31px;
}
h4,
.h4{
  font-size: 24px;
  line-height: 28px;
}
h5,
.h5{
  font-size: 20px;
  line-height: 22px;
  text-transform: uppercase;
}
blockquote{
  color: $brand-primary;
  font-size: 28px;
  line-height: 31px;
  font-weight: 700;
  font-style: italic;
}
@media(min-width: $breakpoint-mobile){
  html,body{
    font-size: 20px;
    line-height: 22px;
  }
  h1,
  .h1{
    font-size: 45px;
    line-height: 48px;
  }
  h2,
  .h2{
    font-size: 38px;
    line-height: 42px;
  }
  h3,
  .h3{
    font-size: 32px;
    line-height: 35px;
  }
  h4,
  .h4{
    font-size: 26px;
    line-height: 29px;
  }
  h5,
  .h5{
    font-size: 24px;
    line-height: 26px;
    text-transform: uppercase;
  }
  blockquote{
    font-size: 32px;
    line-height: 35px;
  }
}
@media(min-width: $breakpoint-tablet){
  html,body{
    font-size: 24px;
    line-height: 34px;
  }
  h1,
  .h1{
    font-size: 50px;
    line-height: 55px;
  }
  h2,
  .h2{
    font-size: 40px;
    line-height: 45px;
  }
  h3,
  .h3{
    font-size: 35px;
    line-height: 38px;
  }
  h4,
  .h4{
    font-size: 28px;
    line-height: 31px;
  }
  h5,
  .h5{
    font-size: 26px;
    line-height: 28px;
    text-transform: uppercase;
  }
  blockquote{
    font-size: 40px;
    line-height: 45px;
  }
}
.background-gradient{
  color: #fff;
  a{
    color: #fff;
  }
  h1,h2,h3,h4,h5,
  .h1,.h2,.h3,.h4,.h5{
    color: #fff;
  }
}