$brand-primary: #4367B0;
$brand-primary-gradient: #24375E;
$brand-gray: #e7e7e7;

$text-color: #000;

$accent-color-1: #00727C;
$accent-color-2: #87C7E3;
$accent-color-3: #41A1D0;
$accent-color-4: #00C7CC;

/* Sizes */
$breakpoint-mobile-portrait: 600px;
$breakpoint-mobile: 768px;
$breakpoint-tablet: 1024px;
$breakpoint-menu: $breakpoint-tablet;

$content-width: 1280px;

/* Navigation */
$navigation-height-mobile: 70px;
$navigation-height: 100px;

/* Styles */
$box-shadow: 1px 2px 4px rgba(0, 0, 0, .4);
$box-shadow-right: 4px 0px 6px rgba(0,0,0,.4);
$box-shadow-bottom: 4px 0px 6px rgba(0,0,0,.4);