*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul{
  list-style: none;
}
a{
  color: $brand-primary;
  text-decoration: none;
}
a,
button{
  &:active,
  &:hover,
  &:focus,
  &:visited{
    outline: 0;
  }
}
video,
img{
  display: block;
  width: 100%;
  vertical-align: middle;
}
video{
  cursor: pointer;
}