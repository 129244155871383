.reveal-container{
  .reveal{
    opacity: 0;
    transition: all 0.5s ease-in-out;
    transform: translateY(40px);
    &.reveal-horizontal{
      transform: translate(-40px,0);
    }
    @for $i from 1 through 4{
      &:nth-of-type(#{$i}){
        transition-delay: $i*0.2s;
      }
    }
  }
  &.reveal-active{
    .reveal {
      opacity: 1;
      transform: translateY(0);
    }
  }
}