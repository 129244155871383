nav{
  width: 100%;
  height: $navigation-height-mobile;
  line-height: $navigation-height-mobile;
  @media(min-width: $breakpoint-menu){
    height: $navigation-height;
    line-height: $navigation-height;
  }
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
  .nav-wrapper{
    margin-left: -10px;
    margin-right: -10px;
    @media(min-width: $breakpoint-menu){
      margin-left: -15px;
      margin-right: -15px;
    }
    .nav-element{
      float: left;
      position: relative;
      padding: 0 10px;
      @media(min-width: $breakpoint-menu){
        padding: 0 15px;
      }
      &.nav-logo{
        width: 60%;
        @media(min-width: $breakpoint-menu){
          width: 20%;
        }
        img{
          display: inline-block;
          @media(min-width: $breakpoint-mobile){
            width: 60%;
          }
          @media(min-width: $breakpoint-tablet) {
            width: 100%;
          }
          &.img-desktop{
            display: none;
          }
          @media(min-width: $breakpoint-tablet+1) {
            &.img-mobile {
              display: none;
            }
            &.img-desktop{
              display: inline-block;
            }
          }
        }
      }
      &.nav-toggle{
        width: 20%;
        text-align: right;
        @media(min-width: $breakpoint-menu){
          width: 20%;
          display: none;
        }
        label.nav-toggle--button {
          display: inline-block;
          width: 35px;
          height: 45px;
          position: relative;
          transition: border-radius .5s;
          vertical-align: middle;
          margin: auto;
          .line {
            position: absolute;
            height: 3px;
            width: 35px;
            background: #fff;
            border-radius: 2px;
            display: block;
            transition: 0.2s;
            transform-origin: center;
          }

          .line:nth-child(1) { top: 14px; }
          .line:nth-child(2) { top: 22px; }
          .line:nth-child(3) { top: 30px; }

          &.checked .line:nth-child(1){
            transform: translateY(8px) rotate(-45deg);
          }

          &.checked .line:nth-child(2){
            opacity:0;
          }

          &.checked .line:nth-child(3){
            transform: translateY(-8px) rotate(45deg);
          }
        }
        input#nav-toggle {display:none}
        @media(min-width: $breakpoint-menu){
          display: none;
        }
      }
      &.nav-main{
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 1201;
        top: $navigation-height-mobile;
        left: 0;
        transform: translateX(100%);
        transition: transform .2s;
        @media(max-width: $breakpoint-menu - 1){
          padding: 15px 30px;
          background: $brand-primary;
        }
        text-align: left;
        &.open{
          transform: translateX(0);
        }
        ul{
          list-style: none;
          li{
            border-bottom: 1px solid #fff;
            &:last-child{
              border-bottom: none;
            }
            a{
              display: block;
              color: #fff;
              padding: 15px 0;
              font-size: 22px;
            }
          }
        }
        @media(min-width: $breakpoint-menu) {
          position: static;
          top: auto;
          left: auto;
          transform: translateX(0);
          display: block;
          width: 55%;
          text-align: center;
          ul{
            display: inline-block;
            li{
              border-bottom: 0;
              display: inline-block;
              a{
                display: block;
                padding: 0 10px;
              }
            }
          }
        }
      }
      &.nav-language{
        font-size: 22px;
        width: 20%;
        @media(min-width: $breakpoint-menu){
          width: 25%;
        }
        #nav-lang-toggle{
          span{
            display: none;
          }
          img{
            display: inline-block;
            width: 50px;
          }
          @media(min-width: 1050px){
            span{
              display: inline;
              border-bottom: 1px solid #fff;
              margin-right: 10px;
            }
          }
        }
        #nav-lang{
          position: fixed;
          width: 100%;
          height: 100%;
          z-index: 1202;
          top: $navigation-height-mobile;
          left: 0;
          transform: translateX(100%);
          transition: transform .2s;
          background: $brand-primary;
          padding: 15px 30px;
          &.open{
            transform: translateX(0);
          }
          ul{
            list-style: none;
            li{
              border-bottom: 1px solid #fff;
              &:last-child{
                border-bottom: none;
              }
              a{
                position: relative;
                display: block;
                color: #fff;
                padding: 20px 40px 20px 0;
                font-size: 22px;
                img{
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 50px;
                }
              }
            }
          }
          @media(min-width: $breakpoint-menu) {
            position: absolute;
            width: 100%;
            height: auto;
            top: $navigation-height;
            transform: translateX(0);
            display: none;
            &.open{
              display: block;
            }
            ul{
              li{
                border-bottom: none;
                a{
                  padding: 15px 30px 15px 0;
                  line-height: 35px;
                  border-top: 1px solid transparent;
                  border-bottom: 1px solid transparent;
                  transition: border-color .2s;
                  &:hover{
                    border-top: 1px solid #fff;
                    border-bottom: 1px solid #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
    &:after{
      display: block;
      content: '';
      clear: both;
    }
  }
}